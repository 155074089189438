<template>
  <div class="wrapper">
    <div class="loader" v-if="loaded">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <Auth></Auth>
    <div class="control-block">
      <div class="control-block__top">
        <div class="control-block__csv">
          <a-button @click="loadCSV" style="background: #44b872; border: 1px solid #44b872" type="primary" class="control-block__csv-button">Скачать CSV</a-button>
          <label style="margin-left: 10px;">Строк для выгрузки
            <a-input style="width: 60px; margin-left: 10px;" placeholder="Лимит строк" v-model="limitCSV"></a-input>
          </label>
        </div>
        <div class="tables">
          <a-select ref="select" class="tables-select" default-value="table-1" style="width: 120px">
            <a-select-option value="table-1" selected>Table 1</a-select-option>
            <a-select-option value="table-2">Table 2</a-select-option>
          </a-select>
          <a-button style="z-index: 300;" type="danger" @click="clearSortFilter">Сбросить фильтр</a-button>
        </div>
      </div>
      <div class="control-block__bottom">
        <form class="rows" @submit.prevent="changeCountRows">
          <a-input value="50" v-model="paginate.count" placeholder="Basic usage"/>
          <p>Строк из {{ countRows }}</p>
        </form>
        <div class="paginate">
          <a-button :disabled="loaded === true" v-for="page in paginate.pages" class="paginate-btn"
                    :type="paginate.curentPage === page.label ? 'primary' : 'default'" @click="makePaginate(page.label)">
            {{ page.label }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="table-header">
        <header-item @closeColumns="closeColumns()" @getData="getData()"
                     v-for="column in convertColumn" :column="column"></header-item>
      </div>
      <div class="table-rows">
        <rows @baseGet="getData()" v-for="row in dataRows" :row="row" :convertColumn="convertColumn"></rows>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderItem from "@/components/HeaderItem";
import rows from "@/components/rows";
import Auth from "@/views/Auth";
import {request} from './api.js'

export default {
  data() {
    return {
      paginate: {
        curentPage: '',
        lastPage: '',
        pages: [],
        count: '',
      },
      debug: true,
      countRows: 0,
      orderedColumns: [],
      columnNames: [],
      dataRows: [],
      convertColumn: [],
      loaded: true,
      limitCSV: 50
    };
  },
  computed: {
  },
  components: {
    HeaderItem, rows, Auth
  },
  methods: {
    async getColumns() {
      this.convertColumn = []
      let res = await request('v1/data/columns', 'POST')
      this.orderedColumns = res.json.orderedColumns
      this.columnNames = res.json.columnNames
      this.convertArrays()
    },
    async getData() {
      this.loaded = true
      let res = await request('v2/data/get', 'POST')

      this.countRows = res.json.data.total
      this.dataRows = res.json.data.data

      if(!localStorage.getItem('closed'))
        localStorage.setItem('closed', JSON.stringify({}))

      this.setColPaginate(res.json.data.current_page, res.json.data.links)
      this.closeColumns()
      this.loaded = false
    },
    convertArrays() {
      this.convertColumn = [{
        'bd_name': 'lead',
        "title": 'Лид',
        "filtr_width": 40,
      }]

      this.orderedColumns.forEach(item => {
        this.columnNames.forEach(value => {
          if (item === value.bd_name)
            this.convertColumn.push(value)
        })
      })
    },

    makePaginate(page) {
      localStorage.setItem('page', page)
      this.paginate.curentPage = localStorage.page
      this.getData()
    },

    clearSortFilter() {
      localStorage.setItem('filter', JSON.stringify({}))
      localStorage.setItem('sorted', JSON.stringify({}))
      this.getColumns()
      this.getData()

    },
    setColPaginate(curent, links) {
      this.paginate.curentPage = curent.toString()
      let pagesNew = []
      links.forEach(item => {
        if (item.label !== '...' && item.label !== '&laquo; Previous' && item.label !== 'Next &raquo;') {
          pagesNew.push(item)
        }
      })
      this.paginate.pages = pagesNew
    },
    start(){
      this.getColumns()
      if (localStorage.page)
        this.paginate.curentPage = localStorage.page
      else
        this.paginate.curentPage = '1'

      this.getData()
    },
    closeColumns(){
      let closed = JSON.parse(localStorage.getItem('closed'))
      for (let item in closed) {
        let column = document.querySelector(`[data-name-column="${closed[item]}"]`)
        column.querySelector('.column-closed').classList.add('closed')
        column.querySelector('.header-item_control').classList.add('closed')
        column.classList.add('closed')

        let rows = document.querySelectorAll(`[data-name-row="${closed[item]}"]`)
        rows.forEach(item => {
          item.querySelector('.table-row__wrapp').classList.add('closed')
          item.classList.add('closed')
        })
      }
    },
    changeCountRows(){
      console.log('true')
      localStorage.paginate = this.paginate.count
      this.start()
    },
    async loadCSV(){
      let res = await request('v2/data/get', 'POST', true, this.limitCSV)
      window.open('https://' + res.json.csvurl, '_blank');
    }
  },
  mounted() {
    this.start()
    if(localStorage.paginate)
      this.paginate.count = localStorage.paginate
    else{
      localStorage.paginate = '50'
      this.paginate.count = localStorage.paginate
    }
    if (!localStorage.getItem('filter'))
      localStorage.setItem('filter', JSON.stringify({}))

    if (!localStorage.getItem('sorted'))
      localStorage.setItem('sorted', JSON.stringify({}))
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
*{
  box-sizing: border-box;
}

.wrapper {
  position: relative;
  padding-top: 100px;
}

.control-block {
  width: 100%;
  height: 100px;
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 150;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}
.control-block__top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.control-block__top .tables-select{
  margin-right: 20px;
}

.control-block__bottom{
  display: flex;
  align-items: center;
  width: 100%;
}
.control-block__bottom .rows{
  margin-right: 20px;
}

.control-block .rows {
  display: flex;
  align-items: center;
  height: 20px;
}

.control-block .rows input {
  width: 50px;
  text-align: center;
  margin-right: 5px;
}

.control-block .rows p {
  margin: 0;
}

.paginate .paginate-btn {
  margin: 0 5px;
  cursor: pointer;
}

.table-header {
  display: flex;
  width: 6145px;
  top: 100px;
  position: sticky;
  z-index: 100;
  background: #f1f1f1;
  border-top: 1px solid rgba(17, 6, 6, 0.45);
  border-bottom: 1px solid rgba(17, 6, 6, 0.45);
}
.loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(187, 177, 177, 0.4);
  z-index: 130;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  z-index: 202;
  border-radius: 50%;
  background: #6fb7cb;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
