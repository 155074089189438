<template>
  <div class="table-row" @click="clickRow">
    <div class="table-row__item" v-for="order in convertColumn" :class="closed[order.bd_name] ? 'closed' : order.textAlign ? 'right' : ''" :data-name-row="order.bd_name" :style="{'min-width': order.filtr_width + 'px', 'max-width':  order.filtr_width + 'px'}">
      <div class="table-row__wrapp" :class="closed[order.bd_name] ? 'closed' : order.textAlign ? 'right' : ''">
        <div v-if="order.bd_name === 'link'">
          <a target="_blank" :href="row[order.bd_name]">Пер.</a>
        </div>
        <div v-else-if="order.bd_name === 'description'" style="display: flex; justify-content: center; width: 100%">
          <a-tooltip :overlayStyle="{'min-width': 600 + 'px'}"  placement="left" :title="row[order.bd_name]">
            <a-button type="primary" :style="{padding: 2 + 'px', 'font-size': 12 + 'px'}">Описание</a-button>
          </a-tooltip>
        </div>
        <div v-else-if="order.bd_name === 'address'" style="display: flex; justify-content: center; width: 100%">
          <a-tooltip :overlayStyle="{'min-width': 300 + 'px'}"  placement="left" :title="row[order.bd_name]">
            <a-button type="primary" :style="{padding: 2 + 'px', 'font-size': 12 + 'px'}">Адрес</a-button>
          </a-tooltip>
        </div>
        <div v-else-if="order.bd_name === 'title'" style="display: flex; justify-content: center; width: 100%">
          <a-tooltip :overlayStyle="{'min-width': 300 + 'px'}"  placement="left" :title="row[order.bd_name]">
            <a-button type="primary" :style="{padding: 2 + 'px', 'font-size': 12 + 'px'}">Заголовок</a-button>
          </a-tooltip>
        </div>
        <div v-else-if="order.bd_name === 'lead'" style="display: flex; justify-content: center; width: 100%">
          <a-button :disabled="lead.disabled === true" type="primary" @click="sendLead" style="padding: 4px;" >{{ lead.text }}</a-button>
        </div>
        <form @submit.prevent="submitChangeEnter" class="table-row__input" v-else-if="order.can_change === 1" style="padding: 4px;">
          <a-input @focusout="submitChange" style="font-size: 12px; padding: 2px;" type="text" v-model="row[order.bd_name]" :data-name="order.bd_name"></a-input>
        </form>
        <form target="_blank" v-else-if="order.bd_name === 'with_photos'" action="http://vps.redmit.beget.tech/img.php" method="post" class="inline">
          <input type="hidden" name="source" :value="getSource">
          <input type="hidden" name="ads_id" :value="row.id">
          <button style="cursor: pointer;" type="submit" name="images_urls" :value="getImages">
            <a-icon type="file-image"/>
          </button>
        </form>
        <p v-else :class="order.textAlign ? 'right' : ''">{{row[order.bd_name]}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {request} from "@/api";

export default {
  data(){
    return {
      closed: [],
      lead: {
        text: 'Лид',
        disabled: false
      }
    }
  },
  computed: {
    getSource(){
      if(this.row.id_source === 'Авито')
        return 2
      else if(this.row.id_source === 'Циан')
        return 1
      else
        return 0
    },
    getImages(){
      let explode = this.row.images.split('\n')
      return JSON.stringify(explode)
    }
  },
  name: "rows",
  props:['convertColumn', 'row'],
  mounted() {
    this.closed = JSON.parse(localStorage.closed)
  },
  methods:{
    async submitChangeEnter(value){
      let input = value.path[0].querySelector('input')
      let changeValue = input.value
      if(this.row.bd_name === 'price_actual')
        changeValue = parseFloat(changeValue) * 1000000

      if(input.getAttribute('data-name') === 'price_sale'){
        // записываем сюда новое значение с Цена прод (млн) умноженное на миллион
        let newSaleValue = input.value * 1000000;

        // рассчитываем новое значение для Цел. Дох. (млн)
        let newTargetProfitValue = newSaleValue - (this.row.price_actual * 1000000) + (this.row.auction * 1000000);

        // новое значение для Цел. Дох. (млн)
        this.row.target_profit = (newTargetProfitValue / 1000000).toFixed(2);
      }

      let changeName = input.getAttribute('data-name')
      let res = await request('v1/data/change', 'POST', false, false,{
        id: this.row.id,
        name: changeName,
        value: changeValue
      })
      this.row[changeName] = changeValue
    },
    async submitChange(value){
      let input = value.path[0]
      let changeValue = input.value
      if(this.row.bd_name === 'price_actual')
        changeValue = parseFloat(changeValue) * 1000000

      if(input.getAttribute('data-name') === 'price_sale'){
        // записываем сюда новое значение с Цена прод (млн) умноженное на миллион
        let newSaleValue = input.value * 1000000;

        // рассчитываем новое значение для Цел. Дох. (млн)
        let newTargetProfitValue = newSaleValue - (this.row.price_actual * 1000000) + (this.row.auction * 1000000);

        // новое значение для Цел. Дох. (млн)
        this.row.target_profit = (newTargetProfitValue / 1000000).toFixed(2);
      }

      let changeName = input.getAttribute('data-name')
      let res = await request('v1/data/change', 'POST', false, false,{
        id: this.row.id,
        name: changeName,
        value: changeValue
      })
      this.row[changeName] = changeValue
    },
    async sendLead(){
      let data = {
        'apartmentDataId': this.row.id,
        'managerId': 1
      }
      await fetch(`https://s1.arina.nosov.tech/api/apartment_offer`, {
        method: "POST",
        headers:{
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(data)
      }).then(response => console.log(response.json()))

      this.lead.text = 'Доб'
      this.lead.disabled = true
    },
    clickRow(e){
      if(e.ctrlKey){
        e.path[1].classList.toggle('active')
      }
    }
  }
}
</script>

<style scoped>
.table-row{
  display: flex;
  border-bottom: 1px solid #ccc;
  width: 6145px;
  cursor: pointer;
}
.table-row:hover{
  background: #eeeeee;
}
.table-row.active{
  background: #eeeeee;
}
.table-row__item{
  border-right: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  font-size: 12px;
}
.table-row__item.right{
  justify-content: flex-end;
}
.table-row__item.closed{
  max-width: 20px !important;
  min-width: 20px !important;
}
.table-row__item p{
  margin: 0;
  padding: 4px;
  word-break: normal;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.table-row__wrapp.right{
  display: flex;
  justify-content: flex-end;
}
.table-row__input input{
  height: 20px;
}
.table-row__wrapp{
  width: 100%;
}
.table-row__item p.right{
  text-align: right;
}
.table-row__wrapp.closed{
   display: none;
 }
</style>
