<template>
  <div class="header-item" :data-name-column="column.bd_name" :style="{'min-width': column.filtr_width + 'px', 'max-width': column.filtr_width + 'px'}">
    <div class="header-item_control">
      <a-tooltip placement="top" :title="column.title">
        <a-icon class="icon-control-plus" type="plus-circle" theme="twoTone" two-tone-color="#80e08a"
                @click="openColumn"/>
        <a-icon class="icon-control-minus" type="minus-circle" theme="twoTone" two-tone-color="#e35b5b"
                @click="closeColumn"/>
      </a-tooltip>
    </div>
    <div class="column-closed" style="width: 100%">
      <div class="header-item__title">{{ column.title }}</div>
      <div class="sort">
        <div class="sort__buttons" v-if="column.have_index === 1">
          <button class="sort__buttons-bottom" :class="bottomSort ? 'active' : ''"
                  @click="sortColumn(column.bd_name, 'desc')">
            <img class="default" src="@/assets/sort.png" alt="Sort">
            <img class="active" src="@/assets/sort-selected.png" alt="Sort">
          </button>
          <button class="sort__buttons-top" :class="topSort ? 'active' : ''" @click="sortColumn(column.bd_name, 'asc')">
            <img class="default" src="@/assets/sort.png" alt="Sort">
            <img class="active" src="@/assets/sort-selected.png" alt="Sort">
          </button>
        </div>
      </div>
      <div class="search">
        <div class="search__form" v-if="column.select === 1">
          <select style="width: 80%" v-model="valueFilter"  name="select" id="select" @change="submitFilter">
            <option v-for="select in column.select_list" :value="select === 'Все записи' ? '' : select">{{ select }}</option>
          </select>
        </div>
        <form class="search__form" v-else-if="column.have_index === 1" @submit.prevent="submitFilter">
          <a-input placeholder="Поиск" v-model="valueFilter" style="font-size: 12px; padding: 2px ;"></a-input>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderItem",
  data() {
    return {
      topSort: false,
      bottomSort: false,
      valueFilter: '',
      valueSelect: '',
    }
  },
  methods: {
    sortColumn(property, type) {
      let sorted = JSON.parse(localStorage.sorted)
      if (type === 'desc' && !this.bottomSort) {
        sorted[property] = type
        this.bottomSort = true
        this.topSort = false
      } else if (type === 'asc' && !this.topSort) {
        sorted[property] = type
        this.bottomSort = false
        this.topSort = true
      } else if(type === 'desc' && this.bottomSort){
        delete sorted[property]
        this.bottomSort = false
      } else if(type === 'asc' && this.topSort){
        delete sorted[property]
        this.topSort = false
      }

      localStorage.sorted = JSON.stringify(sorted)
      this.$emit('getData')
    },
    checkSort() {
      let sorted = JSON.parse(localStorage.sorted)
      let value = sorted[this.column.bd_name]
      if (value) {
        if (value === 'asc') this.topSort = true
        else this.bottomSort = true
      }
    },
    checkFilter() {
      let filter = JSON.parse(localStorage.filter)
      let value = filter[this.column.bd_name]
      if (value) {
        this.valueFilter = value
      }
    },
    submitFilter() {

      let filter = JSON.parse(localStorage.filter)
      if (filter[this.column.bd_name] && !this.valueFilter) {
        delete filter[this.column.bd_name]
        localStorage.filter = JSON.stringify(filter)
        localStorage.page = '1'
        this.$emit('getData')
        return
      } else if (this.valueFilter === '') return

      filter[this.column.bd_name] = this.valueFilter
      localStorage.filter = JSON.stringify(filter)
      this.$emit('getData')
    },


    closeColumn() {
      let closed = JSON.parse(localStorage.getItem('closed'))
      closed[this.column.bd_name] = this.column.bd_name
      localStorage.setItem('closed', JSON.stringify(closed))
      this.$emit('closeColumns')
    },
    openColumn() {
      let closed = JSON.parse(localStorage.getItem('closed'))
      delete closed[this.column.bd_name]
      localStorage.setItem('closed', JSON.stringify(closed))
      let column = document.querySelector(`[data-name-column="${this.column.bd_name}"]`)
      column.querySelector('.column-closed').classList.remove('closed')
      column.querySelector('.header-item_control').classList.remove('closed')
      column.classList.remove('closed')

      let rows = document.querySelectorAll(`[data-name-row="${this.column.bd_name}"]`)
      rows.forEach(item => {
        item.querySelector('.table-row__wrapp').classList.remove('closed')
        item.classList.remove('closed')
      })
    },
  },
  mounted() {
    this.checkSort()
    this.checkFilter()
  },
  props: ['column']
}
</script>

<style scoped>
.header-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #ccc;
}
.header-item.closed{
  min-width: 20px !important;
  max-width: 20px !important;
}
.header-item__title {
  height: 65px;
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.sort__buttons {
  display: flex;
  flex-direction: column-reverse;
}

.sort__buttons button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.sort__buttons button img.default {
  display: block;
}

.sort__buttons button img.active {
  display: none;
}

.sort__buttons button.active img.default {
  display: none;
}

.sort__buttons button.active img.active {
  display: block;
}


.sort__buttons button img {
  width: 18px;
  height: 17px;
}

.sort__buttons-top {
  transform: rotate(180deg);
}

.sort {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}

.search {
  display: flex;
  align-items: center;
  height: 50px;
  /*padding: 10px 0;*/
}

.search__form {
  display: flex;
  justify-content: center;
}

.search__form input {
  width: 80%;
}

.icon-control {
  cursor: pointer;
}

.header-item_control {
  padding: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}

.icon-control-plus {
  display: none;
}

.icon-control-minus {
  display: block;
}

.header-item_control.closed .icon-control-plus {
  display: block;
}

.header-item_control.closed .icon-control-minus {
  display: none;
}
.column-closed.closed{
  display: none;
}
</style>