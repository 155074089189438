export async function request(url, method, csv = false, limit = false,  change = false) {
    let headerData = {
        method,
        headers: {
            'Content-type': 'application/json'
        },
    }

    let body = {
        select: JSON.parse(localStorage.getItem('filter')),
        sort: JSON.parse(localStorage.getItem('sorted')),
        itemscount: localStorage.paginate,
        page: localStorage.page
    }

    if(csv){
        body['csv'] = '1'
        body['csvlimit'] = limit
    }

    if(change){
        body = change
    }

    headerData['body'] = JSON.stringify(body)

    let res = await fetch('https://arina.minofev.ru/api/' + url, headerData)
    let resJson = await res.json()
    return {json: resJson, status: res.status}
}
