<template>
    <div class="auth-content">
      <form class="auth-form" @submit.prevent="auth">
        <h2 style="text-align: center; margin-bottom: 20px;">Авторизация</h2>
        <a-input placeholder="Login" v-model="login"></a-input>
        <a-input placeholder="Password" v-model="password"></a-input>
        <a-button type="primary">Login</a-button>
      </form>
    </div>
</template>

<script>
export default {
  name: "Auth",
  data(){
    return{
      login: '',
      password: ''
    }
  },
  methods:{
    auth(){

    }
  }

}
</script>

<style scoped>
.auth-content{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  height: 100vh;
  background: #fff;
  z-index: 200;
  justify-content: center;
  align-items: center;
}
.auth-form{
  display: flex;
  flex-direction: column;
  width: 300px;
}
.auth-form input, button{
  width: 100%;
  height: 40px;
  font-size: 20px;
  margin: 10px 0;
}


</style>